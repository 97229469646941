.header {
  display: flex;
  justify-content: space-between;
}
.logo {
  margin-top: -2.5rem;
  width: 10rem;
  height: 10rem;
}
.header-menu {
  list-style: none;
  display: flex;
  gap: 2rem;
  color: #fff;
}

.hamburger-icon-bg {
  background-color: var(--appColor);
  padding: 0.5rem;
  border-radius: 5px;
  margin-top: 1.1rem;
}
.hamburger-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.header-menu > li:hover {
  color: var(--red);
  cursor: pointer;
}

/*responsive design*/
@media screen and (max-width: 768px) {
  .logo {
    margin-left: -1.8rem;
  }
  .header > :nth-child(2) {
    position: fixed;
    right: 2rem;
    z-index: 99;
  }
  .header-menu {
    flex-direction: column;
    background-color: var(--appColor);
    padding: 2rem;
  }
}
